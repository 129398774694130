import Layout from "../../components/layout"
import React from "react"
import SEO from "../../components/seo"
import regulamin_dziennik from "../../files/documents/1_regulamin_dziennik.pdf"
import spis_procedur from "../../files/documents/2_spis_procedur.pdf"
import przemoc_w_rodzinie from "../../files/documents/3_przemoc_w_rodzinie.pdf"
import nierealizowanie_obowiazku_szkolnego from "../../files/documents/4_nierealizowanie_obowiazku_szkolnego.pdf"
import kradziez from "../../files/documents/5_kradziez.pdf"
import substancje_psychoaktywne from "../../files/documents/6_substancje_psychoaktywne.pdf"
import palenie_papierosow from "../../files/documents/7_palenie_papierosow.pdf"
import niebezpieczne_narzedzia from "../../files/documents/8_niebezpieczne_narzedzia.pdf"
import anonimowa_skrzynka from "../../files/documents/9_anonimowa_skrzynka.pdf"
import obowiazek_szkolny from "../../files/documents/10_obowiazek_szkolny.pdf"
import alkohol from "../../files/documents/11_alkohol.pdf"
import podejrzane_przedmioty from "../../files/documents/12_podejrzane_przedmioty.pdf"
import ofiara_czynu_karalnego from "../../files/documents/13_ofiara_czynu_karalnego.pdf"
import sprawca_czynu_karalnego from "../../files/documents/14_sprawca_czynu_karalnego.pdf"
import uczen_agresywny from "../../files/documents/15_uczen_agresywny.pdf"
import trudnosci_wychowawcze from "../../files/documents/16_trudnosci_wychowawcze.pdf"
import uszkodzenie_mienia from "../../files/documents/17_uszkodzenie_mienia.pdf"
import wypadek from "../../files/documents/18_wypadek.pdf"
import pozar from "../../files/documents/19_pozar.pdf"

function DocumentsKindergarden() {
  return (
    <Layout menuType="kindergarden">
      <SEO title="Regulaminy i dokumenty - ZSP Nidek" />
      <h1>Regulaminy i dokumenty</h1>
      <ol className="documents-ol">
        <li>
          <a href={regulamin_dziennik} download="regulamin_dziennik.pdf">
            Regulamin dziennika elektronicznego (273KB | pdf)
          </a>
        </li>
        <li>
          <a href={spis_procedur} download="spis_procedur.pdf">
            Procedury postępowania w sytuacjach kryzysowych w Zespole Szkolno
            Przedszkolnym im. Mikołaja Kopernika w Nidku (392KB | pdf)
          </a>
        </li>
        <li>
          <a href={przemoc_w_rodzinie} download="przemoc_w_rodzinie.pdf">
            Procedura postępowania w przypadku podejrzenia, że dziecko doznaje
            przemocy w rodzinie (380KB | pdf)
          </a>
        </li>
        <li>
          <a
            href={nierealizowanie_obowiazku_szkolnego}
            download="nierealizowanie_obowiazku_szkolnego.pdf"
          >
            Procedura postępowania dotycząca nierealizowania obowiązku szkolnego
            (374KB | pdf)
          </a>
        </li>
        <li>
          <a href={kradziez} download="kradziez.pdf">
            Procedura postępowania w przypadku stwierdzenia faktu kradzieży na
            terenie szkoły (376KB | pdf)
          </a>
        </li>
        <li>
          <a
            href={substancje_psychoaktywne}
            download="substancje_psychoaktywne.pdf"
          >
            Procedura postępowania w przypadku posiadania przez uczniów
            narzędzi, przedmiotów i substancji niebezpiecznych oraz substancji
            psychoaktywnych (387KB | pdf)
          </a>
        </li>
        <li>
          <a href={palenie_papierosow} download="palenie_papierosow.pdf">
            Procedura postępowania nauczycieli w wypadku uzyskania informacji o
            paleniu papierosów (w tym e-papierosów) przez ucznia na terenie
            szkoły lub poza nim (376KB | pdf)
          </a>
        </li>
        <li>
          <a
            href={niebezpieczne_narzedzia}
            download="niebezpieczne_narzedzia.pdf"
          >
            Procedura postępowania w przypadku, gdy nauczyciel podejrzewa, że
            uczeń posiada przy sobie alkohol lub substancję przypominającą
            narkotyk (720KB | pdf)
          </a>
        </li>
        <li>
          <a href={anonimowa_skrzynka} download="anonimowa_skrzynka.pdf">
            Procedura funkcjonowania anonimowej skrzynki na sygnały dotyczące
            bezpieczeństwa szkoły (188KB | pdf)
          </a>
        </li>
        <li>
          <a href={obowiazek_szkolny} download="obowiazek_szkolny.pdf">
            Procedura postępowania w przypadku zaniedbywania przez uczniów
            wypełniania obowiązków szkolnych (295KB | pdf)
          </a>
        </li>
        <li>
          <a href={alkohol} download="alkohol.pdf">
            Procedura postępowania w przypadku uzyskania informacji, o
            spożywaniu przez ucznia alkoholu lub używaniu środków odurzających,
            uprawiania nierządu bądź przejawiania innych zachowań świadczących o
            demoralizacji (w tym: naruszenie zasad współżycia społecznego,
            popełnienie czynu zabronionego, systematyczne uchylanie się od
            obowiązku szkolnego, włóczęgostwo, udział w działalności grup
            przestępczych) (385KB | pdf)
          </a>
        </li>
        <li>
          <a href={podejrzane_przedmioty} download="podejrzane_przedmioty.pdf">
            Stwierdzenie na terenie szkoły podejrzanych przedmiotów lub
            substancji nie będących w posiadaniu uczniów (441KB | pdf)
          </a>
        </li>
        <li>
          <a
            href={ofiara_czynu_karalnego}
            download="ofiara_czynu_karalnego.pdf"
          >
            Procedura postępowania wobec ucznia – ofiary czynu karalnego lub
            przestępstwa (368KB | pdf)
          </a>
        </li>
        <li>
          <a
            href={sprawca_czynu_karalnego}
            download="sprawca_czynu_karalnego.pdf"
          >
            Procedura postępowania wobec ucznia – sprawcy czynu karalnego lub
            przestępstwa (412KB | pdf)
          </a>
        </li>
        <li>
          <a href={uczen_agresywny} download="uczen_agresywny.pdf">
            Procedura postępowania w przypadku agresywnego zachowania ucznia
            (386KB | pdf)
          </a>
        </li>
        <li>
          <a href={trudnosci_wychowawcze} download="trudnosci_wychowawcze.pdf">
            Procedura postępowania z uczniem sprawiającym trudności wychowawcze
            (383KB | pdf)
          </a>
        </li>
        <li>
          <a href={uszkodzenie_mienia} download="uszkodzenie_mienia.pdf">
            Procedura postępowania w przypadku stwierdzenia uszkodzenia lub
            zniszczenia mienia szkolnego (368KB | pdf)
          </a>
        </li>
        <li>
          <a href={wypadek} download="wypadek.pdf">
            Procedura postępowania w przypadku zaistnienia wypadku podczas zajęć
            szkolnych, zajęć pozalekcyjnych oraz podczas przerw śródlekcyjnych
            (546KB | pdf)
          </a>
        </li>
        <li>
          <a href={pozar} download="pozar.pdf">
            Procedura postępowania w przypadku zagrożenia pożarem, wybuchem lub
            zatruciem (372KB | pdf)
          </a>
        </li>
      </ol>
    </Layout>
  )
}

export default DocumentsKindergarden
